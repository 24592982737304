// btn
.btn {
	@include media-breakpoint-up(xl) {
		min-width: 200px;
	}

	padding: 0.6rem 1.5rem;
	border-radius: 3px;
	font-size: 18px;
	font-weight: 700;
	font-family: $font-family-secondary;
	text-transform: uppercase;

	// btn-primary
	&.btn-primary {
		&:hover {
			background-color: $yellow;
			border-color: $yellow;
			color: $green;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		color: $green;

		&:hover {
			background-color: transparent;
			border-color: $yellow;
			color: $yellow;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}
}

// card-btn
.card-btn {
	font-size: 0;
	line-height: 1;
	text-decoration: none;

	&::before {
		content: '\f061';
		font-family: $font-awesome;
		color: $yellow;
		font-size: 30px;
		font-weight: 400;
		transition: 0.5s;
	}

	&:hover {
		margin-left: 5px;
		&::before {
			color: $green;
		}
	}
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
