// footer
.footer {
	margin-top: 8vh;

	// footer-outro
	.footer-outro {
		margin: 6vh 0;
		padding: 0 15px;

		.container-holder {
			padding: 30px 0;

			@include media-breakpoint-down(lg) {
				text-align: center;
			}

			@include media-breakpoint-up(lg) {
				margin: 0;
				padding: 45px 30px;
				font-size: 20px;
			}

			background-color: $white;
			box-shadow: $shadow;
		}

		// footer-logolink
		.footer-logolink {
			@include media-breakpoint-up(lg) {
				position: absolute;
				right: 15px;
				bottom: 0;
			}

			ul {
				@include media-breakpoint-down(lg) {
					justify-content: center;
				}

				li {
					+li {
						margin-left: 15px;
					}

					a {
						display: block;
						width: 60px;
						height: 60px;
						background-color: $yellow;
						border-radius: 100%;
						color: $white;
						font-size: 26px;
						line-height: 60px;
						text-align: center;

						&:hover {
							background-color: $green;
						}
					}
				}
			}
		}
	}

	// footer-address
	.footer-address {
		margin: 6vh 0;
		text-align: center;

		.image {
			width: 260px;
			margin: 0 auto 15px auto;

			img {
				width: 100%;
			}
		}
	}

	// footer-partners
	.footer-partners {
		position: relative;
		margin-top: 55px;
		padding: 45px 0;
		background-color: $white;

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin: 15px;
			}
		}

		&::before {
			content: url('/images/curve-up-white.svg');
			position: absolute;
			left: -5px;
			bottom: 100%;
			width: calc(100% + 5px);
			font-size: 0;
			line-height: 0;
		}
	}

	// footer-copyright
	.footer-copyright {
		padding: 30px;
		background-color: $green;

		ul {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;

			@include media-breakpoint-up(md) {
				flex-direction: row;
				justify-content: center;
			}

			margin: 0;
			padding: 0;
			list-style: none;

			li {
				@include media-breakpoint-up(md) {
					margin: 0 15px;
				}

				color: $white;
				font-size: 14px;

				a {
					color: $white;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

}
