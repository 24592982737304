// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background-color: $white;
	transition: 0.5s;
	filter: drop-shadow(0 3px 6px rgba($black, 0.15));

	@include media-breakpoint-up(xl) {
		padding-top: 10px;
	}

	&::after {
		content: url('/images/curve-down-white.svg');
		z-index: -1;
		position: absolute;
		top: 100%;
		left: -5px;
		width: calc(100% + 5px);
		font-size: 0;
		line-height: 0;
	}

	.container,
	.container-fluid {
		.container-holder {
			.column {
				@include media-breakpoint-down(xs) {
					padding-right: 90px;
				}

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
				align-items: flex-start;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		align-self: center;

		.navbar-toggler {
			display: flex;
			align-items: center;
			padding: 2px 10px 2px 2px;
			border: none;
			background-color: $green;
			border-radius: 3px;
			color: $white;
			font-size: 18px;
			font-weight: 700;
			text-transform: uppercase;
			font-family: $font-family-secondary;
			box-shadow: $shadow;
			transition: 0.5s;

			i {
				width: 30px;
				margin-left: 2px;
				font-size: 20px;
				line-height: 30px;
				text-align: center;
				text-transform: uppercase;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}

			&:hover {
				background-color: $yellow;
				color: $green;
			}
		}

	}

	// logo
	.logo {
		width: 160px;
		margin: 0 auto;

		@include media-breakpoint-up(xl) {
			margin: 0 15px -55px 0;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				margin-top: 15px;

				>.nav-item {
					>.nav-link {
						display: flex;
						align-items: center;
						color: $green;
						font-size: 16px;

						&:hover {
							color: $yellow;
						}
					}

					&.active {
						>.nav-link {
							color: $yellow;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 15px;

							.nav-link {
								color: $green;

								&:hover {
									color: $yellow;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $yellow;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				>.nav-item {
					margin-right: 5px;
					font-size: 16px;
					font-weight: 400;
					font-family: $font-family-secondary;
					text-transform: uppercase;

					>.nav-link {
						color: $green;

						&:hover {
							color: $yellow;
						}
					}

					&.active {
						>.nav-link {
							color: $yellow;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 10px;
						border-radius: 0;
						border-color: $green;

						&::before {
							border-bottom-color: $green;
							border-bottom-width: 9px;
						}

						.nav-item {
							font-size: 16px;
							line-height: 1.2;
							text-transform: none;
							white-space: nowrap;

							.nav-link {
								color: $green;

								&:hover {
									color: $yellow;
								}
							}

							&.active {
								.nav-link {
									color: $yellow;
								}
							}
						}
					}
				}
			}
		}

	}

	// quick-contact
	div.quick-contact {
		@include media-breakpoint-down(xs) {
			position: absolute;
			top: 0;
			right: 15px;
		}

		ul {
			@include media-breakpoint-down(lg) {
				margin-right: -0.5rem;
			}

			li {
				margin-right: 5px;
				font-size: 14px;
				font-family: $font-family-secondary;

				a {
					display: block;
					color: $green;

					@include media-breakpoint-up(xl) {
						color: rgba($green, 0.6);
					}

					padding: 0.5rem;
					text-decoration: none;

					i {
						font-size: 18px;

						@include media-breakpoint-up(xl) {
							display: none;
						}
					}

					.list-item-title {
						@include media-breakpoint-down(lg) {
							display: none;
						}
					}

					&:hover {
						color: $yellow;
					}
				}
			}
		}
	}

	// book-button
	div.book-button {
		position: absolute;
		top: 40px;
		right: 15px;

		@include media-breakpoint-up(xl) {
			right: 30px;
		}

		ul {
			li {
				a {
					@extend .btn;
					@extend .btn-secondary;
					min-width: auto !important;
					padding: 0.15rem 1rem !important;
					box-shadow: $shadow;

					i {
						@include media-breakpoint-up(sm) {
							display: none;
						}
					}

					.list-item-title {
						@include media-breakpoint-down(xs) {
							display: none;
						}
					}
				}
			}
		}
	}

}

// home
&.home {
	.header {
		@include media-breakpoint-up(xl) {
			position: fixed;
		}
	}
}
