// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// text-large
.text-large {
	@include media-breakpoint-up(xl) {
		font-size: 20px;
	}
}

// container
.container-fluid {
	max-width: 1920px
}

.container-one-column,
.container-two-columns {
	.container-holder {
		.column {
			&.column-wide {
				@include media-breakpoint-up(md) {
					@include make-col(8);
				}
			}

			&.column-narrow {
				@include media-breakpoint-up(md) {
					@include make-col(4);
				}
			}
		}
	}
}

// default-card
.default-card {
	border-radius: 0;
	border: none;
	box-shadow: $shadow;

	.card-body {
		@include media-breakpoint-up(xl) {
			padding: 20px;
		}

		.card-caption {
			.card-title-link {
				color: $green;
			}

			.card-title {
				font-size: 24px;

				&:hover {
					color: $yellow;
				}
			}

			.card-subtitle {
				font-size: 20px;
			}
		}
	}
}

// gallery
.gallery,
.gallery-horizontal {
	.gallery-item {
		.gallery-link {
			box-shadow: $shadow;
		}
	}
}
