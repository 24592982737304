// mini-sab
.mini-sab {
	z-index: 100;
	position: relative;
	margin-bottom: calc(6vh + 55px);
	padding: 30px 0;
	background: $primary;
	filter: drop-shadow(0 3px 6px rgba($black, 0.15));

	&::before {
		content: url('/images/curve-up-green.svg');
		position: absolute;
		left: -5px;
		bottom: 100%;
		width: calc(100% + 5px);
		font-size: 0;
		line-height: 0;
	}

	&::after {
		content: url('/images/curve-down-green.svg');
		position: absolute;
		top: 100%;
		left: -5px;
		width: calc(100% + 5px);
		font-size: 0;
		line-height: 0;
	}

	.container {
		@extend .d-flex;
		@extend .justify-content-center;
	}
}
