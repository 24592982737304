// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin-right: 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		margin: 30px 0;

		.card {
			@extend .default-card;
		}

		&.dark {
			margin: 45px 0;

			&.grid {
				.grid-items {
					margin-bottom: -45px;

					.item {
						margin-bottom: 45px;

						.card {
							background: transparent;
							box-shadow: none;

							.card-body {
								flex: none;
								padding: 20px 0 0 0;

								.card-caption {
									color: $white;

									.card-title {
										color: $yellow;

										&:hover {
											color: $white;
										}
									}
								}
							}

							.card-buttons {
								.card-btn {
									&:hover {
										&::before {
											color: $white;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	position: relative;

	&::before {
		content: url('/images/curve-down-gray.svg');
		z-index: 10;
		position: absolute;
		top: 0;
		left: -5px;
		width: calc(100% + 5px);
		font-size: 0;
		line-height: 0;
	}

	&::after {
		content: url('/images/curve-up-green.svg');
		z-index: 10;
		position: absolute;
		left: -5px;
		bottom: 0;
		width: calc(100% + 5px);
		font-size: 0;
		line-height: 0;
	}

	&.alt {
		&::before {
			content: url('/images/curve-down-green-alt.svg');
		}

		&::after {
			content: url('/images/curve-up-gray-alt.svg');
		}
	}

	// owl-carousel
	.owl-carousel {
		height: 75vh;
		min-height: 300px;
		max-height: 720px;

		.item {
			height: 75vh !important; // overide
			min-height: 300px;
			max-height: 720px;
			padding: unset; // overide
			align-items: flex-end;

			.owl-container {
				position: relative;

				.owl-caption {
					max-width: 620px;
					margin-bottom: 120px;
					padding: 30px;
					border-radius: 3px;
					background: rgba($white, 1);
					color: $black;
					text-align: left;
					text-shadow: none;

					.owl-title {
						color: $green;
						@include font-size($h1-font-size);

						@include media-breakpoint-up(xl) {
							font-size: 36px;
						}
					}

					.owl-subtitle {
						font-size: 20px;
						font-weight: 400;
						font-family: $font-family-primary;
					}

					.owl-btn {
						min-width: auto !important;
						margin-top: 15px;
						padding: 0 !important;
						background-color: transparent !important;
						border-color: transparent !important;
						font-size: 0;
						line-height: 1;
						text-decoration: none;

						&::before {
							content: '\f061';
							font-family: $font-awesome;
							color: $yellow;
							font-size: 36px;
							font-weight: 400;
							transition: 0.5s;
						}

						&:hover {
							margin-left: 5px;

							&::before {
								color: $green;
							}
						}
					}
				}
			}
		}

		.owl-dots {
			bottom: 4vw;

			.owl-dot {
				span {
					width: 40px;
					height: 8px;
					border: none;
					border-radius: 2px;
					background-color: rgba($white, 0.75);
					box-shadow: none;
				}

				&:hover,
				&.active {
					span {
						background-color: $yellow;
					}
				}
			}
		}

		.owl-nav {
			display: none;
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
