// old base file

// override source styling: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
.mini-sab .tommy-zeb-wrapper {
    float: none;
    width: auto;
}
.mini-sab .tommy-zeb-wrapper * { font-family: $font-family-base !important; font-size: 16px; float: none !important}
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left {
    flex-wrap: wrap;
    float: none;
    background: none;
    color: $black;
    padding: 0 !important;
}
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left label {
	color: $green;
	font-size: 12px;
	font-weight: 400;
	font-family: $font-family-tertiary !important;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left { justify-content: center !important;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
    margin-right: 1px;
	padding: 3px 15px 3px 0;
    border-right: none;
	background: $white;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date {
	padding: 0;
	background: transparent;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories {
	border-radius: 3px 0 0 3px;
}
.mini-sab .tommy-zeb-wrapper-mini h2.ng-binding { display: none;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-label { display: none;}

/* Accommodatietype */
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types { display: block !important;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types h2.ng-binding {
    display: block;
    margin: 0;
	padding-left: 15px;
    color: $green;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
	font-family: $font-family-tertiary !important;
    text-align: left;
    text-transform: none;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types select { max-width: 200px; min-width: 150px; outline: none;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category select { width: 120px; outline: none;}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input { line-height: 20px;}
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input,
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select { padding: 5px; margin: 0; width: 100%; outline: none;}
.mini-sab .tommy-zeb-period { display: flex !important;}
.mini-sab .tommy-zeb-period .tommy-zeb-date-begin-label,
.mini-sab .tommy-zeb-period .tommy-zeb-date-end-label { float: none !important; display: block;}
.mini-sab .tommy-zeb-period .tommy-zeb-date-begin-label label, .mini-sab .tommy-zeb-period .tommy-zeb-date-end-label label { margin-bottom: 0 !important; line-height: 20px; display: block; font-size: 12px; font-weight: 400;}
.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date-begin,
.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date-end {
    padding: 3px 0 3px 15px;
    float: none;
    margin-bottom: 0;
	background: $white;
}
.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date-begin { margin-right: 1px;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories, .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date {
    border-right: none;
    padding-right: 0;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period input {
    background-color: #fff;
    border: none;
    border-radius: 0;
    padding: 3px 10px;
    height: 28px;
}
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select { padding-top: 4px;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period input { width: 130px;}

.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-update {
    min-width: 150px;
	height: 100%;
    margin: 0 !important;
    padding: 7px 20px;
    border-radius: 0 3px 3px 0;
    border: 1px solid $secondary;
    background-color: $secondary;
    color: $green;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
	font-family: $font-family-secondary !important;
    text-transform: uppercase;
}
.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-update:hover {
    border-color: $white;
    background-color: $white;
    color: $green;
}

/* collapse functionaliteit */
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories h2.ng-binding {
    display: block;
    margin: 0;
	padding-left: 15px;
    color: $green;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
	font-family: $font-family-tertiary !important;
    text-align: left;
    text-transform: none;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories h2.ng-binding::after { display: none;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories-collapse {
    display: flex;
    min-width: auto;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories-collapse .tommy-zeb-travelcompany-total {
    border: none;
    border-radius: 0;
	outline: none;
    padding: 3px;
    width: 40px !important;
    height: 28px;
    margin-top: 0 !important;
	margin-left: 0 !important;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-category-list {
    z-index: 99;
    background-color: #fff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    color: #000;
    width: 100%;
    max-width: 100%;
    min-width: 260px;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-category-list .ng-scope { padding: 0;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category {
    flex-wrap: nowrap;
    margin: 5px 0;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category .tommy-zeb-accommodation-person-category-label {
    flex: 1 1 auto;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category .tommy-zeb-accommodation-person-category-choice {
    flex: 0 0 85px;
    align-self: center;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-choice .ng-binding {
    min-width: 20px;
    line-height: 22px;
    text-align: center;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-range {
    background-color: $primary;
    color: #fff;
    line-height: 22px;
    transition: all 0.5s ease;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-range:hover {
    background-color: $secondary;
    color: #fff;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-range span {
    line-height: 24px;
    top: 0!important;
}

.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-change-person-categories {
    height: 28px;
    padding: 3px 15px;
	border: none;
    color: #000;
    font-weight: 400;
    border-radius: 3px;
    text-transform: none;
    background-color: #fff;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category-mini .tommy-zeb-person-categories-collapse button.tommy-zeb-btn { margin-top: 0 !important;}
.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-change-person-categories::after {
    font-family: "Font Awesome 5 Pro";
    content: "\f107";
    margin-left: 15px;
	font-weight: 900;
	font-size: 14px;
}

@include media-breakpoint-down(md) {

    .mini-sab .tommy-zeb-person-categories {
		width: calc(50% - 1px);
	}
	.mini-sab .tommy-zeb-accommodation-types {
		width: calc(50% - 1px);
		border-radius: 0 3px 3px 0;
	}
	.mini-sab .tommy-zeb-date {
		width: 100%;
		margin: 5px 0;

		.tommy-zeb-date-begin {
			flex-grow: 1;
			border-radius: 3px 0 0 3px;

		}
		.tommy-zeb-date-end {
			flex-grow: 1;
			border-radius: 0 3px 3px 0;
		}
	}
	.mini-sab tommy-confirm-update {
		display: block;
		width: 100%;

		.tommy-zeb-btn {
			width: 100%;
			height: 54px !important;
			border-radius: 3px !important;
		}
	}
}

@media (min-width: 768px) and (max-width: 1199px) {

    .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-label,
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-begin-label,
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-end-label { width: auto;}

}
@media (max-width: 767px) {

    .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left { display: block;}
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date,
    .mini-sab .tommy-zeb-wrapper-mini .ng-isolate-scope { float: none; clear: both;}
    .mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn { margin: 0 !important;}
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-begin-label,
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-end-label { width: auto;}
    .mini-sab .tommy-zeb-wrapper-mini tommy-confirm-update { display: block;}

    .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types select { max-width: none;}
    .mini-sab .tommy-zeb-wrapper .tommy-zeb-left select,
    .mini-sab .tommy-zeb-wrapper .tommy-zeb-left input,
    .mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn {
        display: block !important;
        width: 100% !important;
    }

    .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date { margin-right: 0;}
	.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-update { margin-top: 5px !important;}
	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types { margin-bottom: 5px !important; border-radius: 3px !important;}
	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories {
		display: block;
		margin-bottom: 5px !important;
		margin-right: 0;
		border-radius: 3px;
		.tommy-zeb-btn {
			text-align: left;
		}
	}
}


.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
    display:none !important;  
}