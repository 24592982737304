// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 75vh;
	min-height: 300px;
	max-height: 1080px;

	@include media-breakpoint-up(xl) {
		min-height: 540px;
		height: calc(100vh - 110px);
	}
}

// eyecatcher
.eyecatcher {
	.owl-carousel {
		.item {
			.owl-container {
				z-index: 1;
				position: relative;

				.owl-caption {
					text-align: left;
					text-shadow: 0 0 10px rgba($black, 0.15);

					.owl-title {
						margin-bottom: 0;
						@include font-size($h1-font-size);

						@include media-breakpoint-up(xl) {
							font-size: 60px;
						}
					}

					.owl-subtitle {
						font-size: 24px;
						font-weight: 400;
						font-family: $font-family-primary;
					}

					.owl-description {
						max-width: 540px;
					}
				}
			}
		}
	}

	.owl-dots {
		bottom: 4vw;

		.owl-dot {
			span {
				width: 40px;
				height: 8px;
				border: none;
				border-radius: 2px;
				background-color: rgba($white, 0.75);
				box-shadow: none;
			}

			&:hover,
			&.active {
				span {
					background-color: $yellow;
				}
			}
		}
	}

	.owl-nav {
		display: none;
	}
}
